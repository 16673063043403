<template>
    <div>
      <PageLoader v-bind:storage="appLoading" />
      <v-layout wrap justify-center>
        <v-flex xs12 py-10>
          <span
            style="font-family: poppinsbold; color: black; letter-spacing: 1px"
            :style="{
              'font-size': $vuetify.breakpoint.name == 'xl' ? '32px' : '28px',
            }"
            >Gallery: Industry Safety Consulting</span
          >
        </v-flex>
      </v-layout>
      <v-layout wrap justify-center>
        <v-flex xs12 pa-2 v-if="category.bgfSafetyConsultingDetails">
          <template v-if="category.bgfSafetyConsultingDetails.slidderImage">
          <masonry
            :cols="{ default: 4, 1000: 3, 700: 1, 400: 1 }"
            :gutter="{ default: '30px', 700: '15px' }"
          >
            <div
              class="scene scene--card pb-7"
              v-for="item in category.bgfSafetyConsultingDetails.slidderImage"
              :key="item"
            >
          <!-- <viewer
            :images="category.bgfSafetyConsultingDetails.slidderImage"
            :key="category.bgfSafetyConsultingDetails.slidderImage"
          > -->
            <!-- <span
              v-for="item in category.bgfSafetyConsultingDetails.slidderImage"
              :key="item"
              class="pa-2"
            > -->
              <v-img
                :src="mediaURL + item"
                eager
                :lazy-src="mediaURL + item"
                :alt="item"
              >
              </v-img>
              </div>
              </masonry>
              </template>
            <!-- </span>
          </viewer> -->
        </v-flex>
      </v-layout>
    </div>
  </template>
    <script>
  import axios from "axios";
  export default {
    data() {
      return {
        appLoading: false,
        ServerError: false,
        showMore: false,
        limit: 10,
        pages: 0,
        page: 1,
        currentPage: 1,
        totalData: 0,
        totalPage: 0,
        category: "",
        slider: [],
      };
    },
    beforeMount() {
      this.getData();
    },
  
    methods: {
      getData() {
        this.appLoading = true;
        axios({
          url: "/category/view",
          method: "GET",
          params: {
            id: this.$route.query.id,
          },
        })
          .then((response) => {
            this.appLoading = false;
            this.category = response.data.data;
            this.courses = response.data.courses;
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
    },
  };
  </script>